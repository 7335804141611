import apihelper from "@/helpers/apihelper";
export default {
  async getLocationCodes(context, location) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/locationcodes?location=${location}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to location codes.",
    });
    return responseData.data;
  },
  async getPlanning(context, { from, until, location, order_by }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/roosterlw?from=${from}&until=${until}&location=${location}&order_by=${order_by}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch schedule.",
    });
    return responseData.data;
  },
  async getPlanningTotal(context, { from, until, location }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/roosterlwtotalen?from=${from}&until=${until}&location=${location}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch schedule totals.",
    });
    return responseData.data;
  },
  async saveCode(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/saveCode`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to change code.",
    });
  },
  async getChauffeurDetail(context, { id }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/infochauffeur/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch chauffeur detail.",
    });
    return responseData.data;
  },

  async printAanwezigheidslijst(context, { date, location }) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/printaanwezigheidslijst?date=${date}&location=${location}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to print aanwezigheidslijst.",
    });
    return responseData.data;
  },

  async emailAanwezigheidslijst(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/emailAanwezigheidslijst`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to email aanwezigheidslijst.",
    });
  },

  async saveChauffeurOpmerking(context, payload) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/saveInfoChauffeur`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to save opmerking.",
    });
  },

  async getSaldoUren(context, { from}) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/saldouren?from=${from}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch saldo uren.",
    });
    return responseData.data;
  },

  async getChauffeurs(context, {date, gepland, verberg_delijn}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/dailydrivers?date=${date}&gepland=${gepland}&verberg_delijn=${verberg_delijn}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch drivers.",
    });
    return responseData.data;
  },
  async getRitten(context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/dailyrides`;
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      errorMsg: "Failed to fetch ritten.",
      payload: JSON.stringify(payload)
    });
    return responseData;
  }, 
  async getCars(context, {aantalpersonen, geenbus, busverplicht}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/selectcars?aantalpersonen=${aantalpersonen}&geenbus=${geenbus}&busverplicht=${busverplicht}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch cars.",
    });
    return responseData.data;
  },
  async assignDriver(context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/assignDriver`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to assign driver.",
    });
  },
  async removeDriver(context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/removeDriver`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to remove driver.",
    });
  },

  async calcRoute(context, {id}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/calcroute/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to calculate route.",
    });
    return responseData.data;
  },

  async getRouteOptions(context, {id}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/findnext/${id}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to calculate route suggestions.",
    });
    return responseData.data.data;
  },

  async checkPlanning(context, {id, date}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/calcworktimedriver?pers_id=${id}&date=${date}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to check planning.",
    });
    return responseData.data;
  },

  async driverGepland(context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/driverGepland`;
    payload.user = {
      id: context.rootGetters["user/userData"].id,
      name: context.rootGetters.userName,
    };
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to change driver status.",
    });
  },

  async selectGeenWerk(context, {datum}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/zonderwerk?datum=${datum}`;
    const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to select chauffeurs zonder werk.'});
    return responseData.data;
},

  async emailZonderWerk(context, date){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/emailzonderwerk?datum=${date}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to mail.",
    });
    return responseData.data;
  },

  async emailChauffeursZonderWerk(context, date){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/emailchauffeurszonderwerk?datum=${date}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to mail chauffeurs.",
    });
    return responseData.data;
  },

  async changePlanning(context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/changePlanningZonderwerk`;
    await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to change planning code.",
    });
  },

  async getAbsence14Days(context){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/lijstafwezig`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get list.",
    });
    return responseData.data;
  },

  async getOverEight(context, { date}) {
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/lijst8uren?date=${date}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to fetch saldo uren.",
    });
    return responseData.data;
  },

  async exportIll(context, {from,until}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/lijstziekte?from=${from}&until=${until}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get list.",
    });
    return responseData.data;
  },
  async exportIllPeriod(context, {from,until}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/lijstziekteperiodes?from=${from}&until=${until}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get list.",
    });
    return responseData.data;
  },

  async toggleDelijnPlanned(context,{date}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/planning/chauffeursdelijngepland?date=${date}`;
    await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to toggle status.",
    });
  },

  async exportDelijnOpti(context, {from,until}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/optiplanner/optimaliseerDeLijn?from=${from}&until=${until}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get list.",
    });
    return responseData.data;
  },
  async getDelijnOptiMap(context, {from,until}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/optiplanner/googlemapsdata?from=${from}&until=${until}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get mapdata.",
    });
    return responseData.data;
  },
  async exportDelijnDrivers(context, {from,until}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/optiplanner/drivers_delijn?from=${from}&until=${until}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get list.",
    });
    return responseData.data;
  },
  async exportDelijnRides(context, {from,until}){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/optiplanner/rides_delijn?from=${from}&until=${until}`;
    const responseData = await apihelper.fetchGetRequest({
      url,
      token,
      errorMsg: "Failed to get list.",
    });
    return responseData.data;
  },
  async copyDagDeLijn (context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/copyDagDeLIjn`;
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to copy day.",
    });
    return responseData?.status;
  },
  async copyDagDeLijnHistoriek (context, payload){
    const token = context.rootGetters.token;
    const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/copyDagDeLIjnhistoriek`;
    const responseData = await apihelper.fetchPostRequest({
      url,
      token,
      payload: JSON.stringify(payload),
      errorMsg: "Failed to copy day.",
    });
    return responseData?.status;
  }
};
